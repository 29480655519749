import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

class Attributions extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Attributions" />
        <Container>
          <Row>
            <Col sm={12} className='mt-5'>
              <h4>Attributions</h4>
              <ul>
                <li> Icons - <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></li>
                <li> Vectors - <a href="https://www.freepik.com/free-photos-vectors/business">www.freepik.com</a></li>
                <li> Illustrations - <a href="https://www.vecteezy.com">www.vecteezy.com</a></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Attributions

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
